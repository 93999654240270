import axios from 'axios';

// const url = "http://127.0.0.1:5000/api/"
// const url = "http://3.133.47.230:5000/api/"
// const url = "https://pennprs.org/api/" 

if (process.env.NODE_ENV === "development") {
  var url = "http://127.0.0.1:5000/api/"
} else {
  var current = window.location.href.split("pennprs")[0]
  var url = current + "pennprs.org/api/"

}

const instance = axios.create({
  baseURL: url,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  },
  withCredentials: true 
});

export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      baseURL: instance.defaults.baseURL,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
      withCredentials: true,
      params: params
    }).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, {
      baseURL: instance.defaults.baseURL,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": "true",
      },
      withCredentials: true
    }).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}

export function postFile(url, file, email) {
  let formData = new FormData();
  formData.append('file', file);
  formData.append('email', email);

  return new Promise((resolve, reject) => {
    axios.post(url, formData, {
      baseURL: instance.defaults.baseURL,
      headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Credentials": "true",
      },
      withCredentials: true
    }).then(
      (response) => {
        resolve(response.data);
      },
      (err) => {
        reject(err);
      }
    );
  });
}