import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useSelector } from "react-redux";
import apis from "../../api";
import ConfirmWindow from "../../component/ConfirmWindow";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";

export const JobPage = () => {
  const userEmail = useSelector((state) => state.user.email);
  const [jobs, setJobs] = useState([
    {
      id: "Loading",
      name: "Loading",
      status: "Loading",
      type: "Loading",
      trait: "Loading",
      created_at: "Loading",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false); // Loading state for the page

  const current = window.location.href.split("job")[0];
  const base_dir = current.includes("pennprs")
    ? current + "api/download?filename="
    : current.split("3000")[0] + "5000/api/download?filename=";

  const handleDownloadClick = (downloadLink) => {
    setIsLoading(true);
    window.location.href = downloadLink;
    setIsLoading(false);
  };

  const determineStatus = (job) => {
    if (job.status === "completed") {
      const downloadLink = `${base_dir}${userEmail}=${job.id}`;
      return (
        <div>
          <Typography
            variant="body2"
            style={{ color: "#4caf50", textAlign: "center", fontWeight: "bold" }}
          >
            {job.status}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => handleDownloadClick(downloadLink)}
            style={{ marginTop: "5px", textTransform: "none", fontSize: "14px" }}
          >
            Download Result
          </Button>
        </div>
      );
    } else if (job.status === "failed") {
      const downloadLink = `${base_dir}${userEmail}=${job.id}`;
      return (
        <div>
          <Typography
            variant="body2"
            style={{ color: "#e66b00", textAlign: "center", fontWeight: "bold" }}
          >
            {job.status}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => handleDownloadClick(downloadLink)}
            style={{ marginTop: "5px", textTransform: "none", fontSize: "14px" }}
          >
            Download Error Log
          </Button>
        </div>
      );
    } else if (job.status === "running") {
      return (
        <Typography
          variant="body2"
          style={{ color: "#add8e6", textAlign: "center", fontWeight: "bold" }}
        >
          {job.status}
        </Typography>
      );
    } else if (job.status.includes("queue")) {
      return (
        <Typography
          variant="body2"
          style={{ color: "lightgrey", textAlign: "center", fontWeight: "bold" }}
        >
          {job.status}
        </Typography>
      );
    } else if (job.status.includes("killed")) {
      return (
        <Typography
          variant="body2"
          style={{ color: "#e63600", textAlign: "center", fontWeight: "bold" }}
        >
          {job.status}
        </Typography>
      );
    }
    return job.status;
  };

  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [jobId, setJobId] = useState(null);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleConfirm = () => {
    setConfirm(true);
    setOpen(false);
  };

  const handleDelete = (jobId) => {
    setJobId(jobId);
    handleOpen();
  };

  useEffect(() => {
    if (confirm) {
      apis.deleteJob({ email: userEmail, job_id: jobId }).then((res) => {
        console.log(res);
        setConfirm(false);
      });
    }
  }, [confirm, jobId, userEmail]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      apis.getJobs({ email: userEmail }).then((res) => {
        let temp = [];
        res.forEach((job) => {
          if (!job.hide) {
            temp.push(job);
          }
        });
        setJobs(temp);
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [userEmail]);

  return (
    <Box mt={15}>
      <ConfirmWindow
        open={open}
        title=" ⚠️ Delete Job Confirmation"
        message="Are you sure you want to delete this job?"
        onConfirm={handleConfirm}
        onCancel={handleClose}
      />
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Typography variant="h4" align="center" mb={5} gutterBottom>
        Your Job Submissions
      </Typography>
      <Paper elevation={0} sx={{ padding: "2px", display: "flex", justifyContent: "center" }}>
        <Table
          sx={{
            minWidth: 700,
            maxWidth: "90%", // Limits width for better centering on large screens
            border: "1px solid #ddd",
            "& .MuiTableCell-head": {
              backgroundColor: "#002244",
              color: "#ffffff",
              fontWeight: "bold",
              fontSize: "16px",
              textAlign: "center",
            },
            "& .MuiTableCell-body": {
              fontSize: "14px",
              padding: "12px 8px",
              textAlign: "center", // Centers all body cells
            },
            "& .MuiTableRow-root:hover": {
              backgroundColor: "#f1f8ff",
            },
            "& .MuiTableRow-root:nth-of-type(even)": {
              backgroundColor: "#f9f9f9",
            },
            margin: "0 auto", // Centers the table within the Paper container
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>Job ID</TableCell>
              <TableCell>Job Name</TableCell>
              <TableCell>Job Status</TableCell>
              <TableCell>Method Type</TableCell>
              <TableCell>Creation Date</TableCell>
              <TableCell>Delete Result / Cancel Job</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Typography variant="h6">No job submissions found.</Typography>
                </TableCell>
              </TableRow>
            )}
            {jobs.length > 0 &&
              jobs.map((job) => (
                <TableRow key={job.id}>
                  <TableCell>{job.id}</TableCell>
                  <TableCell>{job.name}</TableCell>
                  <TableCell>{determineStatus(job)}</TableCell>
                  <TableCell>{job.type}-ancestry</TableCell>
                  <TableCell>{job.created_at}</TableCell>
                  <TableCell>
                    {["completed", "failed", "killed"].includes(job.status) ? (
                      <Button
                        onClick={() => handleDelete(job.id)}
                        variant="outlined"
                        sx={{
                          color: "#ff5722",
                          borderColor: "#ff5722",
                          textTransform: "none",
                          fontSize: "14px",
                          "&:hover": {
                            backgroundColor: "#ffe0b2",
                          },
                        }}
                      >
                        <DeleteIcon /> Delete
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleDelete(job.id)}
                        variant="outlined"
                        sx={{
                          color: "#ff5722",
                          borderColor: "#ff5722",
                          textTransform: "none",
                          fontSize: "14px",
                          "&:hover": {
                            backgroundColor: "#ffe0b2",
                          },
                        }}
                      >
                        <CancelIcon /> Cancel
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  );
};
