import React, { useState, useEffect } from "react";

export const ResultPage = () => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    fetch("/results_meta_data.json")
      .then((response) => response.json())
      .then((data) => setTableData(data))
      .catch((error) => console.error("Error loading table data:", error));
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const maxPageNumbersToShow = 5;

  const filteredData = tableData.filter(row =>
    row.pubmedId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.studyId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.diseaseTrait.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.ancestry.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.traitType.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
  const endPage = Math.min(totalPages, startPage + maxPageNumbersToShow - 1);
  const pageNumbers = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => startPage + i
  );

  return (
    <div style={{ padding: '20px', marginTop: "56px" }}>
      <h1>GWAS Catalog</h1>
      <input
        type="text"
        placeholder="Search for a study"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{
          width: '100%',
          padding: '12px 20px',
          margin: '8px 0',
          boxSizing: 'border-box',
          fontSize: '16px'
        }}
      />

      <table style={{
        width: '100%',
        borderCollapse: 'collapse',
        margin: '20px 0',
        fontSize: '14px',
        textAlign: 'left',
        fontFamily: '"Arial", sans-serif',
        color: '#333',
        border: '1px solid #999',
      }}>
        <thead>
          <tr>
            <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>PMID</th>
            <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Study Accession</th>
            <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Disease/Trait</th>
            <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Ancestry</th>
            <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Sample Size</th>
            <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Trait Type</th>
            <th style={{ padding: '10px', border: '1px solid #999', backgroundColor: '#f1f1f1', fontWeight: 'bold', letterSpacing: '0.5px', textTransform: 'uppercase', textAlign: 'center' }}>Download Result</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((row, index) => (
            <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : 'white' }}>
              <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.pubmedId}</td>
              <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>
                <a href={`https://www.ebi.ac.uk/gwas/studies/${row.studyId}`} target="_blank" rel="noopener noreferrer" style={{ color: '#0056b3', textDecoration: 'underline' }}>{row.studyId}</a>
              </td>
              <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.diseaseTrait}</td>
              <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.ancestry}</td>
              <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.sampleSize}</td>
              <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>{row.traitType}</td>
              <td style={{ padding: '10px', border: '1px solid #999', textAlign: 'center' }}>
                <a href={row.downloadLink} target="_blank" rel="noopener noreferrer" style={{ color: '#0056b3', textDecoration: 'underline' }}>Download</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            backgroundColor: currentPage === 1 ? '#ccc' : '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '3px',
            cursor: currentPage === 1 ? 'not-allowed' : 'pointer'
          }}
        >
          Previous
        </button>

        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handleClick(pageNumber)}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              backgroundColor: currentPage === pageNumber ? '#007bff' : '#f1f1f1',
              color: currentPage === pageNumber ? 'white' : 'black',
              border: 'none',
              borderRadius: '3px',
              cursor: 'pointer'
            }}
          >
            {pageNumber}
          </button>
        ))}

        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            backgroundColor: currentPage === totalPages ? '#ccc' : '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '3px',
            cursor: currentPage === totalPages ? 'not-allowed' : 'pointer'
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};
