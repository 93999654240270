import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { IntroductionBlock } from "./introductionBlock";
import { DataBlock } from "./dataBlock";
import { PreMethodBlock } from "./preMethodBlock";
import { MethodBlock } from "./methodBlock";
import { ConfigureBlock } from "./configureBlock";
import { ResultBlock } from "./resultBlock";
import InfoIcon from '@mui/icons-material/Info';

export const SAPage = () => {
  const [step, setStep] = useState(0);

  const handleChangeStep = (newStep) => {
    if (newStep <= step) {
      setStep(newStep);
    }
  };

  const steps = [
    "Introduction",
    "Build Input Data",
    "Select Training Approach",
    "Select Methods",
    "Configure Job",
    "View Results",
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        mt: 8,
      }}
    >
      {/* Sidebar */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "20%",
          minHeight: "100vh",
          padding: 1,
          borderRight: "1px solid #e0e0e0",
        }}
      >
        <Typography variant="h5" sx={{ mb: 1, mt: 5 }}>
          Single-Ancestry Analysis
        </Typography>
        {/* <Typography variant="h5" sx={{ mb: 2 }}>
          Workflow Steps
        </Typography> */}

        <List>
          {steps.map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                selected={step === index} // Highlight based on selection
                onClick={() => handleChangeStep(index)} // Update selected item on click
                sx={{
                  backgroundColor:
                    step === index ? "rgba(0, 0, 0, 0.04)" : "transparent", // Conditional styling
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)", // Hover effect
                  },
                  color: index <= step ? "black" : "grey", // Conditional text color
                  cursor: index <= step ? "pointer" : "default", // Conditional cursor
                  borderRadius: 2,
                  textAlign: "center",
                }}
              >
                <ListItemIcon>
                  {index === 0 ? (
                    <InfoIcon sx={{ mr: 2 }} />
                  ): (
                    <Typography variant="h6" sx={{ mr: 2 }}>
                      Step {index}.
                    </Typography>
                  )}
                </ListItemIcon>
                <ListItemText primary={text}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Content area */}
      <Box sx={{ width: "80%", backgroundColor: "#f5f5f5" }}>
        {step === 0 && <IntroductionBlock setStep={setStep} />}
        {step === 1 && <DataBlock setStep={setStep} />}
        {step === 2 && <PreMethodBlock setStep={setStep} />}
        {step === 3 && <MethodBlock setStep={setStep} />}
        {step === 4 && <ConfigureBlock setStep={setStep} />}
        {step === 5 && <ResultBlock setStep={setStep} />}
      </Box>
    </Box>
  );
};
