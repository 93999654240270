import { createSlice } from '@reduxjs/toolkit';

const initiaJobState = {
  jobEmailOpt: true,
  jobName: "", // single
  jobType: "", // single, multiple
  jobMethods: [],
  jobEnsemble: false,
  traitsSource: [""], // query, upload, past
  traitsDetail: ["GWAS Catalog"], // id for query, path for upload, path for past
  traitsType: [""], // continuous, binary
  traitsName: [""],
  traitsPopulation: [""],
  traitsCol: [{
    id: "",
    chr: "",
    snp: "",
    se: "",
    a1: "",
    a2: "",
    maf: "",
    beta: "",
    p: "",
    n: "",
    neff: "",
    ncase: "",
    ncontrol: ""
  }],
  paraDict: {
    "delta": "0.001,0.01,0.1,1.0",
    "nlambda": "30",
    "lambda_min_ratio": "0.01",
    "alpha": "0.7, 1.0, 1.4",
    "p_seq": "1e-05,3.2e-05,0.0001,0.00032,0.001,0.0032,0.01,0.032,0.1,0.32,1.0",
    "sparse": "FALSE",
    "kb": "500",
    "pval_thr": "5e-08,5e-07,5e-06,5e-05,0.0005,0.005,0.05,0.5",
    "r2": "0.1",
    "Ll": "5",
    "Lc": "5",
    "ndelta": "5"
  },
  editPara: false
};
// TODO: change to all caped
const jobSlice = createSlice({
  name: 'job',
  initialState: initiaJobState,
  reducers: {
    setJobEmailOpt(state, action) {
      state.jobEmailOpt = action.payload;
    },
    setJobName(state, action) {
      state.jobName = action.payload;
    },
    setJobType(state, action) {
      state.jobType = action.payload;
    },
    setJobMethods(state, action) {
      state.jobMethods = action.payload;
    },
    setJobEnsemble(state, action) {
      state.jobEnsemble = action.payload;
    },
    setTraitsSource(state, action) {
      state.traitsSource = action.payload;
    },
    setTraitsDetail(state, action) {
      state.traitsDetail = action.payload;
    },
    setTraitsType(state, action) {
      state.traitsType = action.payload;
    },
    setTraitsName(state, action) {
      state.traitsName = action.payload;
    },
    setTraitsPopulation(state, action) {
      state.traitsPopulation = action.payload;
    },
    setTraitsCol(state, action) {
      state.traitsCol = action.payload;
    },
    setParaDict(state, action) {
      state.paraDict = action.payload;
    },
    setEditPara(state, action) {
      state.editPara = action.payload;
    },
    resetALL(state) {
      return initiaJobState;
    }
  },
});

export const { setJobEmailOpt, setJobName, setJobType, setJobMethods, setJobEnsemble, setTraitsSource, setTraitsDetail, setTraitsType, setTraitsName, setTraitsPopulation, setTraitsCol, setParaDict, setEditPara, resetALL } = jobSlice.actions;
export default jobSlice.reducer;