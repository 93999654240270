import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { styled } from '@mui/system';

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '15px',
    padding: theme.spacing(2),
    boxShadow: '0 3px 5px rgba(0,0,0,0.3)',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#007bff',
  color: 'white',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const AlterWindow = ({ open, title, message, onClose }) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      aria-labelledby="custom-dialog-title"
      aria-describedby="custom-dialog-description"
    >
      <DialogTitle id="custom-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="custom-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton onClick={onClose} autoFocus>
          Close
        </CustomButton>
      </DialogActions>
    </CustomDialog>
  );
};

export default AlterWindow;