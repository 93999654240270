import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import GoogleIcon from "@mui/icons-material/Google";
import { useDispatch, useSelector } from 'react-redux';
import { setUserEmail, setUserName, setUserInstitution } from '../../store/reducers/user';
import { applyMiddleware } from "@reduxjs/toolkit";
import apis from "../../api";

export const WelcomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userEmail = useSelector(state => state.user.email);

  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [institution, setInstitution] = useState("");

  const googleProvider = new GoogleAuthProvider();

  const handleAuth = () => {
    if (isLogin) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("User logged in:", user);
          dispatch(setUserEmail(email));
          apis.get_user_info({ email: email, username: "", institution: "" })
            .then((response) => {
              dispatch(setUserName(response.data.username));
              dispatch(setUserInstitution(response.data.institution));
            })
            .catch((error) => {
              console.error("Error:", error.message);
            });
        })
        .catch((error) => {
          console.error("Error:", error.message);
          if (
            error.code === "auth/invalid-email" ||
            error.code === "auth/wrong-password" ||
            error.code === "auth/invalid-login-credentials"
          ) {
            alert("Invalid login credentials. Please try again.");
          }
        });
    } else {
      if (password.length < 6) {
        alert("Password should be at least 6 characters.");
        return;
      }
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          const user = userCredential.user;
          console.log("User signed up:", user);
          dispatch(setUserEmail(email));
          dispatch(setUserName(username));
          dispatch(setUserInstitution(institution));
          apis.register({ email: email, username: username, institution: institution });          
        })
        .catch((error) => {
          console.error("Error:", error.message);
          if (error.code === "auth/email-already-in-use") {
            alert("Email already in use. Please Login.");
          } else if (error.code === "auth/weak-password") {
            alert("Password is too weak. Please try again.");
          }
        });
    }
  };

  const handlePasswordReset = (resetEmail) => {
    if (!resetEmail) {
      alert("Please enter your email address to reset your password.");
      return;
    }
    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        alert("Password reset email sent. Please check your inbox.");
      })
      .catch((error) => {
        console.error("Error:", error.message);
        alert("Error:", error.message);
        if (error.code === "auth/invalid-email") {
          alert("Invalid email address. Please try again.");
          return;
        }
      });
  };

  const handleGoogleLogin = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        const name = user.displayName;
        const email = user.email;
        apis.register({ email: email, username: name, institution: "" });
        // console.log("User logged in with Google:", user);
        dispatch(setUserEmail(user.email));
        dispatch(setUserName(user.displayName));
        
      })
      .catch((error) => {
        console.error("Error:", error.message);
        alert("Error:", error.message);
      });
  };

  useEffect(() => {
    if (userEmail) {
      navigate("/home");
    }
  }
    , [navigate, userEmail]);


  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        mt: 10,
      }}
    >
      <Typography
        variant="h3"
        component="h2"
        sx={{ mt: 2, color: "#333", fontWeight: "bold", flex1: 1 }}

      >
        <img src="/Logo_PNG/FullColor.png" alt="PennPRS" style={{ width: "50px", height: "50px" }} />
        PennPRS
      </Typography>

      <Typography variant="h5" component="h5" sx={{ mt: 2, mb: 2, color: '#555' }}>
        👋 Welcome! Please Login or Sign Up to start.
      </Typography>

      <Box
        sx={{
          mt: 1,
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          maxWidth: '400px', // Limit width for better readability on larger screens
          backgroundColor: 'white',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)', // Soft shadow for depth
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'medium', color: '#444' }}>
          {isLogin ? "Login" : "Signup"}
        </Typography>

        {!isLogin && (
          <TextField
            label={
              <>
                Username <span style={{ color: 'red' }}>*</span>
              </>
            }
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            margin="normal"
            fullWidth
            sx={{ "& .MuiInputBase-root": { borderRadius: '4px' } }}
          />
        )}

        {!isLogin && (<TextField
          label="Insitution"
          variant="outlined"
          value={institution}
          onChange={(e) => setInstitution(e.target.value)}
          margin="normal"
          fullWidth
          sx={{ "& .MuiInputBase-root": { borderRadius: '4px' } }}
        />)}
        <TextField
          label={
            <>
              Email <span style={{ color: 'red' }}>*</span>
            </>
          }
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          fullWidth
          sx={{ "& .MuiInputBase-root": { borderRadius: '4px' } }}
        />
        <TextField
          label={
            <>
              Password <span style={{ color: 'red' }}>*</span>
            </>
          }
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ "& .MuiInputBase-root": { borderRadius: '4px' } }}
        />
        <Button
          variant="contained"
          onClick={handleAuth}
          sx={{
            mt: 2,
            mb: 1,
            width: '100%',
            height: '48px',
            backgroundColor: '#1976d2',
            ':hover': {
              backgroundColor: '#115293',
            },
            borderRadius: '4px',
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          {isLogin ? "Login" : "Signup"}
        </Button>
        <Button
          color="secondary"
          sx={{
            mt: 1,
            textTransform: 'none',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
          onClick={() => setIsLogin((prev) => !prev)}
        >
          {isLogin ? "New User? Sign up here!" : "Already have an account? Login here!"}
        </Button>
        {isLogin && (
          <Button
            color="secondary"
            sx={{
              mt: 1,
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '1rem',
            }}
            onClick={() => {
              const resetEmail = prompt("Please enter your email address to reset your password.");
              handlePasswordReset(resetEmail);
            }}
          >
            Forgot Password?
          </Button>
        )}
        <Typography variant="h6" sx={{ mt: 2, color: '#666' }}>
          --------------- Or ---------------
        </Typography>
        <Button
          startIcon={<GoogleIcon />}
          onClick={handleGoogleLogin}
          sx={{
            mt: 2,
            width: 'auto',
            height: '48px',
            padding: '0 30px',
            textTransform: 'none',
            backgroundColor: '#fff',
            color: '#757575',
            ':hover': {
              backgroundColor: '#f5f5f5',
            },
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          Login with Google
        </Button>
      </Box>
    </Box>
  );
};
