import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Input,
  Select,
  MenuItem,
  CircularProgress
} from "@mui/material";
import apis from "../../../api";
import { useSelector, useDispatch } from "react-redux";
import {
  setTraitsSource,
  setTraitsDetail,
  setTraitsType,
  setTraitsName,
  setTraitsPopulation,
  setTraitsCol,
} from "../../../store/reducers/job";
import StatusBox from "../../../component/StatusBox";
import AlertWindow from "../../../component/AlertWindow";

export const DataBlock = ({ setStep }) => {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.user.email);
  const store_types = useSelector((state) => state.job.traitsType);
  const store_sources = useSelector((state) => state.job.traitsSource);
  const store_details = useSelector((state) => state.job.traitsDetail);
  const store_names = useSelector((state) => state.job.traitsName);
  const store_population = useSelector((state) => state.job.traitsPopulation);
  const store_col = useSelector((state) => state.job.traitsCol);

  const [name, setName] = useState(store_names[0]);
  const [type, setType] = useState(store_types[0]);
  const [source, setSource] = useState(store_sources[0]);
  const [detail, setDetail] = useState(store_details[0]);
  const [population, setPopulation] = useState(store_population[0]);
  const [colNames, setColNames] = useState(store_col[0]);

  const [nType, setNType] = useState(store_col[0].ncase === "" ? "" : "ncase");
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploading, setUploading] = useState(false);
  const [gwas_catalog, setGwas_catalog] = useState([]);
  const [sourceStatus, setSourceStatus] = useState("required");
  const [columnsMatched, setColumnsMatched] = useState(null);

  const [colNamesStatus, setColNamesStatus] = useState("required");
  const [sampleSizeStatus, setSampleSizeStatus] = useState("required");

  const [past_data_names, setPastDataNames] = useState(["No data uploaded"]);
  const [past_data, setPastData] = useState([]);
  const [selectedPastDataName, setSelectedPastDataName] = useState("No data uploaded");


  const gwas_catalog_study_prefix = "https://www.ebi.ac.uk/gwas/studies/";


  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessgae, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  const handleAlertClose = () => {
    setAlertOpen(false);
    setAlertMessage("");
    setAlertTitle("");
  };

  useEffect(() => {
    fetch("harmonised.txt")
      .then((response) => response.text())
      .then((data) => {
        let lines = data.split("\n");
        let gwas_catalogData = lines.map((line) => {
          let name = line.split("/")[2];
          let link = gwas_catalog_study_prefix + name;
          return { name, link };
        });
        setGwas_catalog(gwas_catalogData);
      })
      .catch((error) => console.error("Error loading the file:", error));
  }, []);

  useEffect(() => {
    apis.getPastData({ email: userEmail }).then((response) => {
      if (response.length > 0) {
        let names = response.map((data) => data.traitsName);
        setPastDataNames(names);
        setPastData(response);
        setSelectedPastDataName(names[0]);
      }
    }
    ).catch((error) => {
      console.log(error);
    });
  }, []);

  const handleLoadPastData = (idx) => {
    setSelectedPastDataName(past_data[idx].traitsName);
    setDetail(past_data[idx].traitsDetail[0]);
    setType(past_data[idx].traitsType[0]);
    setName(past_data[idx].traitsName);
    setPopulation(past_data[idx].traitsPopulation[0]);
    // set all keys in the past_data[idx].traitsCol[0] to lower case\
    let keys = Object.keys(past_data[idx].traitsCol[0]);
    let values = Object.values(past_data[idx].traitsCol[0]);
    let newColNames = {};
    for (let i = 0; i < keys.length; i++) {
      newColNames[keys[i].toLowerCase()] = values[i];
    }
    setColNames(newColNames);
    if (values.some(value => value !== "")) {
      setColumnsMatched("no");
    }
    else {
      setColumnsMatched("yes");
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploading(true);
      try {
        const response = await apis.uploadData(file, userEmail);
        setUploadedFileName(file.name);
        setSourceStatus("ok");
      } catch (error) {
        // alert("Error while uploading the file. Please try again. Error: " + error);
        setAlertOpen(true);
        setAlertTitle("❌ Error");
        setAlertMessage("Error while uploading the file. Please try again. Error: " + error);
      }
      setUploading(false);
      setDetail(file.name);
    }
  };

  const handleColNameChange = (e) => {
    const { name, value } = e.target;
    setColNames({
      ...colNames,
      [name]: value,
    });
  };

  const handleRadioChange = (e) => {
    setSource(e.target.value);
    if (e.target.value === "Upload Data" && store_details[0] !== "GWAS Catalog") {
      setUploadedFileName(store_details[0]);
    }
  };

  useEffect(() => {
    if (source === "Upload Data") {
      setSourceStatus(uploadedFileName === "" ? "required" : "ok");
    }
    if (source === "Query Data") {
      if (detail === "GWAS Catalog") {
        if (colNames.id === "") {
          setSourceStatus("required");
        } else if (!colNames.id.startsWith("GCST")) {
          setSourceStatus("required");
        } else {
          setSourceStatus("ok");
        }
      }
      if (detail === "OpenGWAS") {
        setSourceStatus("ok");
      }
    }
    if (source === "Previous Data") {
      setSourceStatus("ok");
    }
  }, [source, uploadedFileName, colNames, detail]);

  useEffect(() => {
    if (type === "Binary") {
      if (source === "Query Data") {
        console.log(colNames);
        if (colNames.ncase === "" && colNames.ncontrol === "") {
          setNType("neff");
        }
        else if (colNames.ncase !== "" || colNames.ncontrol !== "") {
          if (colNames.neff !== "") {
            setColNames({ ...colNames, ncase: "", ncontrol: "" });
            setNType("neff");
          }
          else {
            setNType("ncase");
          }
        }
        else {
          setNType("ncase");
        }
      }
    }
    if (type === "Continuous") {
      setNType("n");
    }
  }, [type]);

  useEffect(() => {
    if (columnsMatched === "yes") {
      setColNamesStatus("ok");
    } else if (columnsMatched === "no") {
      const isAnyColNameFilled = Object.values(colNames).some(value => value !== "");
      setColNamesStatus(isAnyColNameFilled ? "ok" : "required");
    }
  }, [columnsMatched, colNames]);

  useEffect(() => {
    if (source === "Query Data") {
      if (detail === "GWAS Catalog") {
        if (type === "Binary") {
          if (colNames.ncase === "" && colNames.ncontrol === "" && colNames.neff === "") {
            setSampleSizeStatus("required");
          } else {
            if (colNames.ncase !== "" && isNaN(colNames.ncase)) {
              // setAlertOpen(true);
              // setAlertTitle("❗️ Error");
              // setAlertMessage("Please enter a number for Ncase.");
              setSampleSizeStatus("required");
              return;
            } else if (colNames.ncontrol !== "" && isNaN(colNames.ncontrol)) {
              // setAlertOpen(true);
              // setAlertTitle("❗️ Error");
              // setAlertMessage("Please enter a number for Ncontrol.");
              setSampleSizeStatus("required");
              return;
            } else if (colNames.neff !== "" && isNaN(colNames.neff)) {
              // setAlertOpen(true);
              // setAlertTitle("❗️ Error");
              // setAlertMessage("Please enter a number for Neff.");
              setSampleSizeStatus("required");
              return;
            } else {
              setSampleSizeStatus("ok");
            }
          }
        } else if (type === "Continuous") {
          if (colNames.n === "") {
            setSampleSizeStatus("required");
          } else {
            if (isNaN(colNames.n)) {
              // setAlertOpen(true);
              // setAlertTitle("❗️ Error");
              // setAlertMessage("Please enter a number for N.");
              setSampleSizeStatus("required");
              return;
            } else {
              setSampleSizeStatus("ok");
            }
          }
        }
      }
    }
  }, [source, detail, type, colNames]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (source === "") {
      // alert("Please select the source of your GWAS summary statistics.");
      setAlertOpen(true);
      setAlertTitle("⚠️ Warning");
      setAlertMessage("Please select the source of your GWAS summary statistics.");
      return;
    }
    if (name === "") {
      // alert("Please enter the name of the trait");
      setAlertOpen(true);
      setAlertTitle("⚠️ Warning");
      setAlertMessage("Please enter the name of the trait.");
      return;
    }
    if (uploading) {
      // alert("Please wait for the file GWAS upload to be completed.");
      setAlertOpen(true);
      setAlertTitle("⚠️ Warning");
      setAlertMessage("Please wait for the file GWAS upload to be completed.");
      return;
    }
    if (source === "Upload Data" && uploadedFileName === "") {
      // alert("Please upload the GWAS file or select other GWAS source.");
      setAlertOpen(true);
      setAlertTitle("⚠️ Warning");
      setAlertMessage("Please upload the GWAS file or select other GWAS source.");
      return;
    }
    if (source === "Query Data") {
      if (colNames.id === "") {
        // alert("Please enter the Trait ID for GWAS Catalog.");
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage("Please enter the Trait ID for GWAS Catalog.");
        return;
      }
      if (type === "Binary" && colNames.ncase === "" && colNames.ncontrol === "" && colNames.neff === "") {
        // alert("Please enter the Sample Size for GWAS Catalog.");
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage("Please enter the Sample Size for GWAS Catalog.");
        return;
      }
      if (type === "Continuous" && colNames.n === "") {
        // alert("Please enter the Sample Size for GWAS Catalog.");
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage("Please enter the Sample Size for GWAS Catalog.");
        return;
      }
      if (population === "") {
        // alert("Please select the population of your input data.");
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage("Please select the population of your input data.");
        return;
      }
    }
    if (source === "Query Data") {
      if (!colNames.id.startsWith("GCST")) {
        // alert("Please enter the correct Trait ID for GWAS Catalog. The ID should start with 'GCST'. Please see the example in Tutorial.")
        setAlertOpen(true);
        setAlertTitle("⚠️ Warning");
        setAlertMessage("Please enter the correct Trait ID for GWAS Catalog. The ID should start with 'GCST'. Please see the example in Tutorial.");
        return;
      }
    }
    if (columnsMatched === "no" && colNamesStatus === "required") {
      // alert("Please fill in at least one column name.");
      setAlertOpen(true);
      setAlertTitle("⚠️ Warning");
      setAlertMessage("Please fill in at least one column name.");
      return;
    }

    dispatch(setTraitsSource([source]));
    dispatch(setTraitsDetail([detail]));
    dispatch(setTraitsType([type]));
    dispatch(setTraitsName([name]));
    dispatch(setTraitsPopulation([population]));
    dispatch(setTraitsCol([colNames]));

    setStep(2);
  };

  const gridItemStyle = {
    paddingBottom: 2,
    paddingLeft: 2,
    paddingRight: 2,
    border: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 2,
        boxSizing: "border-box",
      }}
    >
      <AlertWindow
        open={alertOpen}
        title={alertTitle}
        message={alertMessgae}
        onClose={handleAlertClose}
      />

      <Box
        sx={{
          width: "95%",
          display: "flex",
          border: "1px solid #ddd",
          borderRadius: "5px",
          overflow: "auto",
          boxSizing: "border-box",
          pt: 2,
          pl: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxSizing: "border-box",
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 12, sm: 6, md: 12 }}
          >

            {/* Data Sources */}
            <Grid item xs={4} sx={gridItemStyle}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                GWAS Summary Statistics:
              </Typography>
              {source === "Query Data" && (
                <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                  <Typography variant="body2">
                    Please enter the information of the GWAS summary statistics.
                  </Typography>
                  <br></br>
                  <Typography variant="body2">
                    Note: We can query all downloadable the harmonized data provided by the GWAS Catalog. please search for the trait you are interested in and enter the Trait ID and Sample Size in the <a href="/tutorial" target="_blank">Tutorial</a> page. We only support harmonized GWAS Catalog data.
                  </Typography>
                </Box>
              )}
              {source === "Upload Data" && (
                <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                  <Typography variant="body2">
                    We support the following file formats: <b>.txt, .tsv, .csv, .zip, .gz, .gzip, .tar.gz</b> . We accept file seperated by tab, comma, or space. The file should contain the following columns: <b>CHR, SNP, A1, A2, BETA, SE, P, MAF, N</b>. Maximum file size allowed: <b>800MB</b>.
                  </Typography>
                </Box>
              )}
              {source === "Previous Data" && (
                <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                  <Typography variant="body2">
                    All the GWAS summary statistics you have uploaded before are listed here. Please select the one you want to analyze. NOTE: You may change the trait information if needed, but the changed information will not be saved for future use.
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={4} sx={gridItemStyle}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={source}
                  onChange={(e) => handleRadioChange(e)}
                >
                  <FormControlLabel
                    value="Upload Data"
                    control={<Radio />}
                    label="Upload Data"
                  />
                  <FormControlLabel
                    value="Query Data"
                    control={<Radio />}
                    label="Query Data"
                  />
                  <FormControlLabel
                    value="Previous Data"
                    control={<Radio />}
                    label="Previous Data"
                  />
                  {source === "Upload Data" && uploadedFileName === "" && (
                    <Input type="file" onChange={handleFileChange} sx={{ mt: 2 }} disabled={uploading} />
                  )}
                  {source === "Upload Data" && uploading && (
                    <CircularProgress size={20} />
                  )}
                  {source === "Query Data" && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        mt: 2,
                        width: "100%",
                      }}
                    >
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          value={detail}
                          onChange={(e) => setDetail(e.target.value)}
                        >
                          <MenuItem value={"GWAS Catalog"}>GWAS Catalog</MenuItem>
                          {/* <MenuItem value={"OpenGWAS"}>OpenGWAS</MenuItem> */}
                        </Select>
                      </FormControl>

                      <TextField
                        label="Trait ID"
                        variant="outlined"
                        value={colNames.id}
                        sx={{ width: "100%" }}
                        onChange={(e) => setColNames({ ...colNames, id: e.target.value })}
                        error={!colNames.id.startsWith("GCST") && colNames.id !== ""}
                        helperText={!colNames.id.startsWith("GCST") && colNames.id !== "" ? "The ID should start with 'GCST'" : ""}
                      />
                    </Box>
                  )}
                  {source === "Previous Data" && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        mt: 2,
                        width: '100%',
                        overflow: 'hidden',
                        boxSizing: 'border-box',
                      }}
                    >
                      <FormControl variant="outlined">
                        <Select
                          value={selectedPastDataName}
                          onChange={(e) => {
                            setSelectedPastDataName(e.target.value);
                            handleLoadPastData(past_data_names.indexOf(e.target.value));
                          }}
                          SelectDisplayProps={{
                            style: {
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxWidth: '90%',
                              whiteSpace: 'nowrap',
                            },
                          }}
                          sx={{
                            maxWidth: '90%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {past_data_names.map((name, idx) => (
                            <MenuItem key={idx} value={name} sx={{
                              maxWidth: '100% !important',
                              overflow: 'hidden !important',
                              whiteSpace: 'nowrap !important',
                              textOverflow: 'ellipsis !important',
                            }}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                  )}
                  {source === "Upload Data" && uploadedFileName !== "" && (
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
                      <Typography variant="body">
                        Successfully uploaded:  {uploadedFileName}
                      </Typography>
                      <Button variant="outlined" onClick={() => setUploadedFileName("")} sx={{ mt: 0 }}>
                        Re-upload
                      </Button>
                    </Box>
                  )}

                </RadioGroup>
              </FormControl>

            </Grid>
            <Grid item xs={4} sx={gridItemStyle}>
              <StatusBox message="Please select the source of your GWAS summary statistics." status={sourceStatus} />
            </Grid>

            {/* Trait Type */}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <Typography variant="h6">Trait Type:</Typography>
              </Grid>)}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <FormControl component="fieldset" sx={{ width: "100%" }}>
                  <RadioGroup
                    row
                    aria-label="traitType"
                    name="type"
                    value={type}
                  >
                    <FormControlLabel
                      value="Binary"
                      control={<Radio />}
                      label="Binary"
                      onChange={(e) => setType(e.target.value)}
                    />
                    <FormControlLabel
                      value="Continuous"
                      control={<Radio />}
                      label="Continuous"
                      onChange={(e) => setType(e.target.value)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            )}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <StatusBox message={"Please choose the type of trait you are analyzing."} status={type === "" ? "required" : "ok"} />
              </Grid>
            )}

            {/* Sample Size (Query data only) */}
            {type !== "" && source === "Query Data" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <Typography variant="h6">
                  Sample Size:
                </Typography>
              </Grid>
            )}
            {type !== "" && source === "Query Data" && (
              <Grid item xs={4} sx={gridItemStyle}>
                {type === "Binary" && (
                  <FormControl component="fieldset" sx={{ width: "100%" }}>
                    <RadioGroup
                      row
                      value={nType}
                      onChange={(e) => setNType(e.target.value)}
                    >
                      <FormControlLabel
                        value="neff"
                        control={<Radio />}
                        label="Neff"
                      />
                      <FormControlLabel
                        value="ncase"
                        control={<Radio />}
                        label="Ncase & Ncontrol"
                      />

                    </RadioGroup>
                  </FormControl>)}
                {nType === "n" && (
                  <Grid item xs={12} sx={{ width: "100%" }}>
                    <TextField
                      label="N"
                      name="n"
                      value={colNames.n}
                      onChange={handleColNameChange}
                      margin="normal"
                      fullWidth
                      error={isNaN(Number(colNames.n)) && colNames.n !== ""}
                      helperText={isNaN(Number(colNames.n)) && colNames.n !== "" ? "Please enter a number." : ""}
                    />
                  </Grid>
                )}
                {nType === "ncase" && (
                  <Grid container spacing={1} sx={{ width: "100%" }}>
                    <Grid item xs={6}>
                      <TextField
                        label="N Case"
                        name="ncase"
                        value={colNames.ncase}
                        onChange={handleColNameChange}
                        margin="normal"
                        fullWidth
                        error={isNaN(Number(colNames.ncase)) && colNames.ncase !== ""}
                        helperText={isNaN(Number(colNames.ncase)) && colNames.ncase !== "" ? "Please enter a number." : ""}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="N Control"
                        name="ncontrol"
                        value={colNames.ncontrol}
                        onChange={handleColNameChange}
                        margin="normal"
                        fullWidth
                        error={isNaN(Number(colNames.ncontrol)) && colNames.ncontrol !== ""}
                        helperText={isNaN(Number(colNames.ncontrol)) && colNames.ncontrol !== "" ? "Please enter a number." : ""}
                      />
                    </Grid>
                  </Grid>
                )}
                {nType === "neff" && (
                  <Grid item xs={12} sx={{ width: "100%" }}>
                    <TextField
                      label="Neff"
                      name="neff"
                      value={colNames.neff}
                      onChange={handleColNameChange}
                      margin="normal"
                      fullWidth
                      error={isNaN(Number(colNames.neff)) && colNames.neff !== ""}
                      helperText={isNaN(Number(colNames.neff)) && colNames.neff !== "" ? "Please enter a number." : ""}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            {type !== "" && source === "Query Data" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <StatusBox message="Please enter the sample size of your input data." status={sampleSizeStatus} />
              </Grid>
            )}


            {/* Trait Name */}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Trait Name:
                </Typography>
              </Grid>)}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <TextField
                  name="trait"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ width: "100%" }}
                />
              </Grid>)}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <StatusBox message="Please enter the name of your trait." status={name === "" ? "required" : "ok"} />
              </Grid>)}


            {/* Population */}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Population:
                </Typography>
                <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                  <Typography variant="body2">
                    We support analyses on one of the five <a href="https://useast.ensembl.org/Help/View?id=171">super populations</a> defined by the 1000 Genomes Project
                  </Typography>
                </Box>
              </Grid>)}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={population}
                    onChange={(e) => setPopulation(e.target.value)}
                  >
                    <MenuItem value={"European (EUR)"}>
                      European (EUR)
                    </MenuItem>
                    <MenuItem value={"African or African American (AFR)"}>
                      African, Admixed African, African American (AFR)
                    </MenuItem>
                    <MenuItem value={"Admixed American (AMR)"}>
                      Admixed American,  Hispanic / Latino (AMR)
                    </MenuItem>
                    <MenuItem value={"East Asian (EAS)"}>
                      East Asian (EAS)
                    </MenuItem>
                    <MenuItem value={"South Asian (SAS)"}>
                      South Asian (SAS)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>)}
            {source !== "" && (
              <Grid item xs={4} sx={gridItemStyle}>
                <StatusBox message={"Please select the population of your input data."} status={population === "" ? "required" : "ok"} />
              </Grid>)}


            {/* Match Column Names */}
            {source !== "Query Data" && type !== "" && (
              <>
                <Grid item xs={4} sx={gridItemStyle}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Column Names:
                  </Typography>
                </Grid>

                {type === "Binary" && (
                  <Grid item xs={4} sx={gridItemStyle}>
                    <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                      <Typography variant="body2">
                        Are the data columns already matched to the following?
                      </Typography>
                      <Typography variant="body2" component="div">
                        <ul>
                          <li>CHR | Chr | chr | Chromosome </li>
                          <li>SNP | snp | Snpid | Snpid_UKB | RS | Rsid | Rs_id</li>
                          <li>A1 | a1 | Effect_allele | allele1 | allele_1 | alt_allele | EA </li>
                          <li>A2 | a2 | Allele2 | Allele_2 | Ref_allele | Other_allele | NEA </li>
                          <li>BETA | Beta | beta</li>
                          <li>ODDS | odds | Odds_ratio </li>
                          <li>SE | se | Stderr | Std_Error | Stderr_Beta | SE_Beta | Stderr_B </li>
                          <li>P | Pvalue | P_value | pvalue | Pval | P_val | GC_Pvalue </li>
                          <li>MAF | Maf | maf | Effect_allele_frequency | Eaf | FRQ | FRQ_U | F_U</li>
                          <li>N | n</li>
                        </ul>
                        We will delete the redundant columns that are not listed above.
                      </Typography>
                      <RadioGroup
                        row
                        value={columnsMatched}
                        onChange={(e) => setColumnsMatched(e.target.value)}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>
                )}
                {type === "Continuous" && (
                  <Grid item xs={4} sx={gridItemStyle}>
                    <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                      <Typography variant="body2">
                        Are your data columns already matched to the following?
                      </Typography>
                      <Typography variant="body2" component="div">
                        <ul>
                          <li>CHR | Chr | chr | Chromosome </li>
                          <li>SNP | snp | Snpid | Snpid_UKB | RS | Rsid | Rs_id</li>
                          <li>A1 | a1 | Effect_allele | allele1 | allele_1 | alt_allele | EA </li>
                          <li>A2 | a2 | Allele2 | Allele_2 | Ref_allele | Other_allele | NEA </li>
                          <li>BETA | Beta | beta</li>
                          <li>ODDS | odds | Odds_ratio </li>
                          <li>SE | se | Stderr | Std_Error | Stderr_Beta | SE_Beta | Stderr_B </li>
                          <li>P | Pvalue | P_value | pvalue | Pval | P_val | GC_Pvalue </li>
                          <li>MAF | Maf | maf | Effect_allele_frequency | Eaf | FRQ | FRQ_U | F_U</li>
                          <li>N | n</li>
                        </ul>
                        We will delete the redundant columns that are not needed for the analysis.
                      </Typography>
                      <RadioGroup
                        row
                        value={columnsMatched}
                        onChange={(e) => setColumnsMatched(e.target.value)}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>
                )}


                <Grid item xs={4} sx={gridItemStyle}>
                  <StatusBox message={"Please confirm if your columns are matched to the required format."} status={columnsMatched === null ? "required" : colNamesStatus} />
                </Grid>
              </>
            )}

            {source !== "Query Data" && columnsMatched === "no" && (
              <>
                <Grid item xs={4} sx={gridItemStyle}>
                  <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
                    <Typography variant="body2">
                      Please fill in the boxes for names that do not match with ours. If they match, no action is needed.
                    </Typography>

                    {type === "Binary" && (
                      <Typography variant="body2" component="div">
                        <br></br><b>NOTE:</b> If you are using Odds Ratio instead of BETA, please prepare SE for logOR.
                      </Typography>
                    )}

                    {type === "Binary" && (
                      <Typography variant="body2" component="div">
                        <br></br> <b>Neff</b>: Effective sample size = 4/(1/Ncase + 1/N_control)
                        <br></br> <b>Ncase & Ncontrol</b>: number of cases & controls
                      </Typography>
                    )}


                  </Box></Grid>
                <Grid item xs={4} sx={gridItemStyle}>
                  <FormControl component="fieldset">
                    <form noValidate autoComplete="off">
                      <Grid container spacing={2}>

                        <Grid item xs={6}>
                          <TextField
                            label="CHROMOSOME"
                            name="chr"
                            value={colNames.chr}
                            onChange={handleColNameChange}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="SNP/RSID"
                            name="snp"
                            value={colNames.snp}
                            onChange={handleColNameChange}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            label="A1"
                            name="a1"
                            value={colNames.a1}
                            onChange={handleColNameChange}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="A2"
                            name="a2"
                            value={colNames.a2}
                            onChange={handleColNameChange}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label={type === "Binary" ? "BETA/Odds Ratio" : "BETA"}
                            name="beta"
                            value={colNames.beta}
                            onChange={handleColNameChange}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="SE"
                            name="se"
                            value={colNames.se}
                            onChange={handleColNameChange}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="P"
                            name="p"
                            value={colNames.p}
                            onChange={handleColNameChange}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="MAF/EAF"
                            name="maf"
                            value={colNames.maf}
                            onChange={handleColNameChange}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {type === "Binary" && (
                            <FormControl component="fieldset">
                              <RadioGroup
                                row
                                value={nType}
                                onChange={(e) => setNType(e.target.value)}
                              >
                                <>
                                  <FormControlLabel
                                    value="neff"
                                    control={<Radio />}
                                    label="Neff"
                                  />
                                  <FormControlLabel
                                    value="ncase"
                                    control={<Radio />}
                                    label="Ncase & Ncontrol"
                                  />
                                </>
                              </RadioGroup>
                            </FormControl>)}

                          {nType === "n" && (
                            <Grid item xs={12}>
                              <TextField
                                label="N"
                                name="n"
                                value={colNames.n}
                                onChange={handleColNameChange}
                                margin="normal"
                                fullWidth
                              />
                            </Grid>
                          )}
                          {nType === "ncase" && (
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <TextField
                                  label="N Case"
                                  name="ncase"
                                  value={colNames.ncase}
                                  onChange={handleColNameChange}
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  label="N Control"
                                  name="ncontrol"
                                  value={colNames.ncontrol}
                                  onChange={handleColNameChange}
                                  margin="normal"
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          )}
                          {nType === "neff" && (
                            <Grid item xs={12}>
                              <TextField
                                label="Neff"
                                name="neff"
                                value={colNames.neff}
                                onChange={handleColNameChange}
                                margin="normal"
                                fullWidth
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={gridItemStyle}>
                  <StatusBox message={"Entering the column names of your input data is optional but recommended. If not provided, we will automatically detect the column names of your input data."} status={colNamesStatus} />
                </Grid>
              </>
            )}

            {/* Save & Continue Button */}
            <Grid item xs={1} />
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="contained"
                fullWidth
                onClick={() => setStep(0)}
                sx={{
                  color: "white",
                  backgroundColor: "grey",
                  mb: 2,
                  mt: -1,
                  textTransform: 'none',
                  fontSize: 18,
                  fontWeight: 'bold',
                  "&:hover": { backgroundColor: "#0d47a1" },
                  mr: 2 // margin-right to add space between buttons
                }}
              >
                Back to Introduction
              </Button>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                fullWidth
                sx={{
                  color: "white",
                  backgroundColor: "grey",
                  mb: 2,
                  mt: -1,
                  fontSize: 18,
                  fontWeight: 'bold',
                  "&:hover": { backgroundColor: "#0d47a1" },
                }}
              >
                Save & Continue
              </Button>
            </Grid>


          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
