import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  FormControl,
  Checkbox,
  FormLabel,
  Switch,
} from "@mui/material";
import apis from "../../../api";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setJobMethods } from "../../../store/reducers/job";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import StatusBox from "../../../component/StatusBox";

export const MethodBlock = ({ setStep }) => {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.user.email);
  const [selectedModels, setSelectedModels] = useState(["PROSPER"]);
  const [editParams, setEditParams] = useState(useSelector((state) => state.job.editPara));
  const paraDict = useSelector((state) => state.job.paraDict) || {};
  const [tempParaDict, setTempParaDict] = useState(paraDict);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleBack = () => {
    setStep(1);
  };

  const handleSubmit = () => {
    dispatch(setJobMethods(selectedModels));
    setStep(3);
  };

  const gridItemStyle = {
    paddingBottom: 2,
    paddingLeft: 2,
    paddingRight: 2,
    border: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: 5,
        mb: 5,
      }}
    >
      <Box
        sx={{
          width: "95%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          border: "1px solid #ddd",
          borderRadius: "5px",
          pt: 2,
          pl: 2,
          overflow: "auto",
        }}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 12, sm: 12, md: 12 }}
        >
          <Grid item xs={4} sx={gridItemStyle}>
            <Typography variant="h6">
              Method:
            </Typography>
            <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
              <Typography variant="body2">
                We only support PROSPER at the moment.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} sx={gridItemStyle}>
            <FormControl component="fieldset" sx={{ width: "50%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={true}
                        disabled
                        value="PROSPER"
                      />
                    }
                    label="PROSPER"
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={gridItemStyle}>
            <StatusBox
              message="Please select one or more methods."
              status={selectedModels.length === 0 ? "required" : "ok"}
            />
          </Grid>

          <Grid item xs={4} sx={gridItemStyle}>
            <Typography variant="h6" >
              Parameters:
            </Typography>
            <Box sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
              <Typography variant="body2">
                We allow customized parameter settings, but we STRONGLY recommend using the default settings.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={4} sx={gridItemStyle}>

            <FormControl
              component="fieldset"
              sx={{
                width: "60%",
                mb: 3,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center", // Centers the content horizontally
              }}
            >
              {/* Container for the labels and switch */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* "Default" Label */}
                <FormLabel
                  component="legend"
                  sx={{ marginRight: 1, whiteSpace: 'nowrap', color: "black" }} // Prevents label from wrapping
                >
                  Default
                </FormLabel>

                {/* Switch Component */}
                <Switch
                  checked={editParams}
                  onChange={() => setEditParams(!editParams)}
                  color="primary"
                />

                {/* "Customized" Label */}
                <FormLabel
                  component="legend"
                  sx={{ marginLeft: 1, whiteSpace: 'nowrap', color: "black" }}
                >
                  Customized
                </FormLabel>
              </Box>
            </FormControl>

            {editParams && (
              <Box
                sx={{ width: "90%", display: "flex", flexDirection: "column" }}
              >
                <TextField
                  variant="outlined"
                  label="nlambda"
                  fullWidth
                  defaultValue={tempParaDict["nlambda"] || "5"}
                  onChange={(e) => setTempParaDict({ ...tempParaDict, nlambda: e.target.value })}
                  error={tempParaDict["nlambda"] && isNaN(tempParaDict["nlambda"])}
                  helperText={tempParaDict["nlambda"] && isNaN(tempParaDict["nlambda"]) ? "Please enter a number." : ""}
                  sx={{ mb: 2 }}
                />

                <TextField
                  variant="outlined"
                  label="ndelta"
                  fullWidth
                  defaultValue={tempParaDict["ndelta"] || "5"}
                  onChange={(e) => setTempParaDict({ ...tempParaDict, ndelta: e.target.value })}
                  error={tempParaDict["ndelta"] && isNaN(tempParaDict["ndelta"])}
                  helperText={tempParaDict["ndelta"] && isNaN(tempParaDict["ndelta"]) ? "Please enter a number." : ""}
                  sx={{ mb: 2 }}
                />

                <TextField
                  variant="outlined"
                  label="lambda min ratio"
                  fullWidth
                  defaultValue={tempParaDict["lambda_min_ratio"] || "0.01"}
                  onChange={(e) => setTempParaDict({ ...tempParaDict, lambda_min_ratio: e.target.value })}
                  error={tempParaDict["lambda_min_ratio"] && isNaN(tempParaDict["lambda_min_ratio"])}
                  helperText={tempParaDict["lambda_min_ratio"] && isNaN(tempParaDict["lambda_min_ratio"]) ? "Please enter a number." : ""}
                  sx={{ mb: 2 }}
                />

                <TextField
                  variant="outlined"
                  label="Ll"
                  fullWidth
                  defaultValue={tempParaDict["Ll"] || "5"}
                  onChange={(e) => setTempParaDict({ ...tempParaDict, Ll: e.target.value })}
                  error={tempParaDict["Ll"] && isNaN(tempParaDict["Ll"])}
                  helperText={tempParaDict["Ll"] && isNaN(tempParaDict["Ll"]) ? "Please enter a number." : ""}
                  sx={{ mb: 2 }}
                />

                <TextField
                  variant="outlined"
                  label="Lc"
                  fullWidth
                  defaultValue={tempParaDict["Lc"] || "5"}
                  onChange={(e) => setTempParaDict({ ...tempParaDict, Lc: e.target.value })}
                  error={tempParaDict["Lc"] && isNaN(tempParaDict["Lc"])}
                  helperText={tempParaDict["Lc"] && isNaN(tempParaDict["Lc"]) ? "Please enter a number." : ""}
                  sx={{ mb: 2 }}
                />
              </Box>
            )}

          </Grid>

          <Grid item xs={4} sx={gridItemStyle}>
            <StatusBox
              message="You can edit parameters for each method. Otherwise, we will run with default parameters."
              status="optional"
            />
          </Grid>


          {/* Save and Continue */}
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={() => handleBack()}
              sx={{
                color: "white",
                backgroundColor: "grey",
                mb: 2,
                textTransform: "none",
                fontSize: 18,
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0d47a1" },
              }}
            >
              Back to Previous Step
            </Button>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={() => handleSubmit()}
              sx={{
                color: "white",
                backgroundColor: "grey",
                mb: 2,
                textTransform: "none",
                fontSize: 18,
                fontWeight: "bold",
                "&:hover": { backgroundColor: "#0d47a1" },
              }}
            >
              Save and Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
