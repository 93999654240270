import React from 'react';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';

function StatusBox({ message, status }) {
  return (
    <Box
      sx={{
        width: " 80%",
        height: "80%",
        minHeight: "50px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        m: 2,
        border: "1px solid #ddd",
        borderRadius: "5px",
        backgroundColor: status === "required" ? "#E69F00" : status === "ok" ? "#4caf50" : status === "optional" ? "#add8e6" : "#add8e6",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 0.5,
          // mb: 0.5,
        }}
      >
        <Tooltip
          title={
            <Typography color="inherit">
             {message}
            </Typography>
          }
          placement="top"
          arrow
          sx={{
            tooltip: {
              backgroundColor: "#333",
              color: "white",
              fontSize: "0.875rem",
              borderRadius: "4px",
            },
            arrow: {
              color: "#333",
            },
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#333", 
                color: "white", 
                fontSize: "0.875rem",
                borderRadius: "4px", 
              },
            },
            arrow: {
              sx: {
                color: "#333", // Arrow color
              },
            },
          }}
        >
          {status === "required" ? (<WarningIcon/>) : status === "ok" ? (<CheckCircleIcon/>) : status === "optional" ? (<InfoIcon/>) : ""}
        </Tooltip>
        <Typography
          variant="subtitle1"
          component="p"
          sx={{ fontWeight: "bold" }}
        >
          {status === "required" ? "Required" : status === "ok" ? "OK" : status === "optional" ? "Optional" : ""}
        </Typography>
      </Box>
    </Box>
  );
}

export default StatusBox;






