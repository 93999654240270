import React, { useEffect, useState } from "react";
import {
  Box,
  Link as MuiLink,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  TableSortLabel,
  CircularProgress,
  Button,
  Grid,
} from "@mui/material";

const DataTable = ({
  columns,
  rows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleGoToPage,
  totalPages,
  goToPage,
  setGoToPage,
}) => (
  <TableContainer component={Paper} style={{ maxWidth: 1200, margin: 20, borderRadius: 8 }}>
    <Table>
      <TableHead>
        <TableRow style={{ backgroundColor: "#f1f1f1" }}>
          {columns.map((column) => (
            <TableCell
              key={column.id}
              align="center"
              sx={{
                backgroundColor: "#f1f1f1",
                fontWeight: "bold",
                padding: "10px 20px",
                fontSize: "14px",
                textTransform: "uppercase",
                letterSpacing: "0.5px",
              }}
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
          <TableRow
            key={index}
            hover
            style={{ backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white" }}
          >
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align="center"
                style={{
                  padding: "10px 20px",
                  fontSize: "14px",
                  borderBottom: "1px solid #ddd",
                }}
              >
                {column.id === "link" ? (
                  <MuiLink href={row[column.id]} target="_blank" rel="noopener noreferrer" style={{ color: "#007bff", textDecoration: "underline" }}>
                    {row[column.id] ? "View Study" : "N/A"}
                  </MuiLink>
                ) : (
                  row[column.id]
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
      rowsPerPageOptions={[5, 10, 15]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
      sx={{
        "& .MuiTablePagination-toolbar": { padding: "10px 20px" },
        "& .MuiTablePagination-selectLabel, .MuiTablePagination-input, .MuiTablePagination-displayedRows": {
          fontSize: "14px",
        },
      }}
    />
    <Box display="flex" justifyContent="center" alignItems="center" mt={2} sx={{ padding: 2 }}>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item>
          <TextField
            label="Go to page"
            type="number"
            value={goToPage}
            onChange={(e) => setGoToPage(e.target.value)}
            style={{ width: 120 }}
            inputProps={{ min: 1, max: totalPages }}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleGoToPage(goToPage)}
            style={{ height: "40px" }}
          >
            Go
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="textSecondary">
            Page {page + 1} of {totalPages}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </TableContainer>
);

export const TutorialPage = () => {
  const [files, setFiles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [goToPage, setGoToPage] = useState(1);

  useEffect(() => {
    fetch("gwas_catalog.tsv")
      .then((response) => response.text())
      .then((text) => {
        const lines = text.split("\n");
        let data = lines.map((line) => {
          const fields = line.split("\t");
          if (fields.length === 6) {
            const [pubmedId, diseaseTrait, date, initialSampleSize, studyAccession, link] = fields;
            return { pubmedId, diseaseTrait, date, initialSampleSize, studyAccession, link };
          } else {
            console.warn(`Skipping line due to incorrect format: ${line}`);
            return null; 
          }
        }).filter(row => row !== null);

        data = data.sort((a, b) => new Date(b.date) - new Date(a.date));

        setFiles(data);
      })
      .catch((error) => {
        console.error("Error fetching GWAS data:", error);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleGoToPage = (newPage) => {
    const pageNumber = parseInt(newPage, 10);
    if (pageNumber >= 1 && pageNumber <= Math.ceil(files.length / rowsPerPage)) {
      setPage(pageNumber - 1);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSortByDate = () => {
    const isAsc = sortDirection === "asc";
    const sortedFiles = [...files].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return isAsc ? dateA - dateB : dateB - dateA;
    });
    setFiles(sortedFiles);
    setSortDirection(isAsc ? "desc" : "asc");
  };

  const filteredFiles = files.filter((file) =>
    file.pubmedId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    file.diseaseTrait.toLowerCase().includes(searchTerm.toLowerCase()) ||
    file.studyAccession.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalPages = Math.ceil(filteredFiles.length / rowsPerPage);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" flexDirection="column" mt={5} p={5}>
      <Typography variant="h5" style={{ marginBottom: 20, fontWeight: 'bold', color: '#333' }}>
        GWAS Catalog Data
      </Typography>

      <TextField
        label="Search PUBMED, Disease/Trait, or Study Accession"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ marginBottom: 20, width: 500 }}
      />

      {loading ? (
        <CircularProgress />
      ) : (
        <DataTable
          columns={[
            { id: "date", label: "Date" },
            { id: "pubmedId", label: "PUBMED" },
            { id: "diseaseTrait", label: "Disease/Trait" },
            { id: "initialSampleSize", label: "Sample Size & Population" },
            { id: "studyAccession", label: "Study Accession" },
            { id: "link", label: "Link" },
          ]}
          rows={filteredFiles}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleGoToPage={handleGoToPage}
          totalPages={totalPages}
          goToPage={goToPage}
          setGoToPage={setGoToPage}
        />
      )}
    </Box>
  );
};
