// import React, { useState } from 'react';
// import { Card, CardContent, Typography } from '@mui/material';

// function MethodCard(props) {
//     const [isHovered, setIsHovered] = useState(false);

//     const cardStyles = {
//         width: 500,
//         transition: 'transform 0.3s',
//         transform: isHovered ? 'scale(1.05)' : 'scale(1)',
//         cursor: isHovered ? 'pointer' : 'default' // Change cursor to pointer when hovered
//     };

//     return (
//         <Card
//             style={cardStyles}
//             onMouseOver={() => setIsHovered(true)}
//             onMouseOut={() => setIsHovered(false)}
//             onClick={props.onClick}
//         >
//             <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                 <Typography gutterBottom variant="h5" component="div">
//                     {props.name}
//                 </Typography>
//                 <Typography variant="body1" color="textSecondary" component="p">
//                     {props.introduction}
//                 </Typography>
//             </CardContent>
//         </Card>
//     );
// }

// export default MethodCard;
// MethodCard.jsx
import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';

const MethodCard = ({ name, introduction, onClick }) => {
  // Split the introduction by newline characters
  const introductionLines = introduction.split('\n');

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 500,
        borderRadius: 2,
        boxShadow: 3,
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: 6,
        },
        cursor: 'pointer',
      }}
    >
      <CardActionArea onClick={onClick} aria-label={`Select method: ${name}`}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 3,
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            align="center"
            sx={{ fontWeight: 'bold', mb: 1 }}
          >
            {name}
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            component="div"
            align="center" // Ensure alignment is center
            sx={{
              whiteSpace: 'pre-line', // Allows '\n' to create line breaks
              wordBreak: 'break-word',
              textAlign: 'center', // Additional CSS for center alignment
            }}
          >
            {introductionLines.map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MethodCard;
