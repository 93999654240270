import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import createExpirationTransform from 'redux-persist-transform-expire';

const expireTransform = createExpirationTransform({
  expireIn: 86400, 
  expiredState: {}, 
  autoExpire: true 
});

const persistConfig = {
  key: 'root',
  storage,
  transforms: [expireTransform] 
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);
